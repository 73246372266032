<template>
  <div class="w-full">
    <div class="row g-0 vh-100 overflow-hidden">
      <div
        class="col-12 col-xl-6 auth-overlay hidden-md-and-down position-relative"
      >
        <img
          class="auth-overlay__img"
          src="/media/background/buildings.png"
          alt="Gedung Telkom Siang Hari"
        />
        <span class="powered-by">© 2023 Tomps.id by Telkom Indonesia</span>
        <div class="position-absolute auth-overlay__logos d-flex flex-column">
          <div class="d-flex flex-nowrap align-items-center mb-5">
            <span class="text-modul-asset">Portal Asset</span>
          </div>
          <div class="text-left">
            <h1 class="text-white font-size-64 Nunito">
              Kementerian <br />  Badan Usaha Milik Negara
            </h1>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-5 auth-pages">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito');
.Nunito {
  font-family: 'Nunito';
}
.text-modul-asset {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 165%;
  display: flex;
  align-items: center;
  letter-spacing: 0.16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #FFFFFF;
}
.auth-overlay .auth-overlay__img {
  opacity: 1;
  animation: none !important;
}
.auth-overlay .auth-overlay__logos {
  top: 40vh;
  left: 5vw;
  width: 100%;
  z-index: 1;
}
.font-size-64 {
  font-size: 64px;
}
.powered-by {
  font-family: 'Nunito';
  position: absolute;
  bottom: 26px;
  left: 30px;
  color: white;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 165%;
  display: flex;
  align-items: center;
  letter-spacing: 0.16px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #FFFFFF;
}
</style>
