
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { detectSubDomain } from "@/core/helpers/helps";
import AuthPM from "@/components/page-layouts/auth/AuthPm.vue";

export default defineComponent({
  name: "auth",
  components: {
    AuthPM,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {
      detectSubDomain,
    };
  },
});
